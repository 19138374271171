import React, { useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import Details from '../../../common/Details';
import { Button, SideDrawer } from '../../../../components';
import ConfirmDialog from '../../../../components/Modals/ConfirmDialog';
import { useDeleteFeaturedVenueStudio } from '../../../../api/venues';
import { Studio } from '../../../../types/Studio';
import AddFeaturedStudio from './AddFeaturedStudio';

type Props = {
  studios: Studio[];
  venueId: string;
};

const FeaturedVenueStudios = ({ studios = [], venueId: venueId }: Props) => {
  const [openAddDrawer, setOpenDrawer] = useState(false);
  const [featuredStudios, setFeaturedStudios] = useState(studios);

  const handleDelete = (deletedStudioId) => {
    setFeaturedStudios((studios) =>
      studios.filter((p) => p.id !== deletedStudioId),
    );
  };
  const MAX_LIMIT = 6;

  if (!venueId) return <></>;

  return (
    <Box>
      <Details
        title="Featured Studios"
        subTitle={` Choose up to ${MAX_LIMIT} studios of this venue to feature at the top of the
        page`}
        onAddButton={() => {
          if (featuredStudios.length < MAX_LIMIT) {
            setOpenDrawer(true);
          } else {
            alert(
              `Max. of ${MAX_LIMIT} studios can be featured. To add a new one, delete an existing featured studio.`,
            );
          }
        }}
      >
        <>
          {featuredStudios
            .filter((studio) => studio?.id)
            .map((studio) => (
              <FeaturedStudioRow
                key={studio.id}
                studio={studio}
                venueId={venueId}
                onDelete={handleDelete}
              />
            ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawer}
        toggleDrawer={() => setOpenDrawer(false)}
        styles={{
          width: '550px',
        }}
      >
        <AddFeaturedStudio
          venueId={venueId}
          handleClose={(studio) => {
            setOpenDrawer(false);
            setFeaturedStudios((studios) => [...studios, studio]);
          }}
        />
      </SideDrawer>
    </Box>
  );
};

export default FeaturedVenueStudios;
function FeaturedStudioRow({
  studio,
  venueId,
  onDelete,
}: {
  studio: Studio;
  venueId: string;
  onDelete: (studioId: string) => void;
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteMutation = useDeleteFeaturedVenueStudio({
    venueId,
    studioId: studio?.id,
  });
  const { id, name, city, state } = studio;

  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={id}>
          <b> {name}</b> - {city}, {state}
        </ListItem>
        <Button
          label="Delete"
          variant={'contained'}
          color={'error'}
          loading={isLoading}
          onClick={() => {
            setConfirmDelete(true);
          }}
        />
        <ConfirmDialog
          isOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          dialogOptions={{ isLoading }}
          handleConfirmDelete={async () => {
            setLoading(true);
            await deleteMutation.mutateAsync();
            onDelete(id);
            setLoading(false);
            setConfirmDelete(false);
          }}
        />
      </Stack>
    </>
  );
}
